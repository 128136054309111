<template>
  <nav
    class="navbar py-2 px-3 d-lg-flex d-md-flex align-items-center justify-content-between"
    style="position: sticky; top: 0; left: 0; z-index: 1001"
  >
    <div class="navbar__left mr-2 d-flex align-items-center flex-wrap">
      <div
        class="navbar__icon-container d-flex justify-content-center align-items-center rounded-lg flex-wrap"
        style="cursor: pointer"
        @click="navigateToQuizDesign"
      >
        <svg
          class="navbar__icon navbar__icon--left"
          width="8"
          height="14"
          viewBox="0 0 8 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.08671 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z"
            fill="white"
          />
        </svg>
      </div>
      <a
        class="navbar__title ml-4"
        href="/manage/quizzes"
        style="text-decoration: none; color: inherit; cursor: pointer"
        >Dashboard /
        <span v-if="getQuizTitle.length < 16">{{ getQuizTitle || 'Untitled' }}</span>
        <span v-else> {{ getQuizTitle.slice(0, 16) }}...</span></a
      >
    </div>
    <div
      class="navbar__middle d-flex align-items-center justify-content-center flex-wrap"
    >
      <div>
        <!-- Create Route start -->

        <a
          class="navbar__title routeLink px-4 py-2"
          :class="
            isRouteActive
              ? 'router-link-exact-active router-link-active '
              : null
          "
          @click="reloadPageOnDesignClick"
          style="text-decoration: none; color: inherit; cursor: pointer"
        >
          Design
        </a>
        <!-- Create Route end -->
        <!-- Connect Route start -->
        <a
          class="navbar__title routeLink px-4 py-2"
          :class="
            isConnectRouteActive
              ? 'router-link-exact-active router-link-active '
              : null
          "
          @click="reloadPageOnConnectClick"
          style="text-decoration: none; color: inherit; cursor: pointer"
        >
        Configuration
        </a>
        <a
          class="navbar__title routeLink px-4 py-2"
          :class="
            isShareRouteActive
              ? 'router-link-exact-active router-link-active '
              : null
          "
          @click="reloadPageOnShareClick"
          style="text-decoration: none; color: inherit; cursor: pointer"
        >
        Share
        </a>
      
      </div>
    </div>
  </nav>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";

import globalMixin from "../../../GlobalMixins/globalMixin.js";

export default {
  data() {
    return {
      // DashboardQuizKey:this.$store.state.QuizKey,
      DashboardQuizKey: this.$route.params.id,
    };
  },
  mixins: [globalMixin],
  async created() {
    //   localStorage.removeItem("QuizID")
    this.ResetQuizKey();
    this.ResetQuizId();

    const QuizKey = this.$route.params.id;
    //   const RouteName = this.$route.name
    console.log(QuizKey);
    if (QuizKey) {
      if (this.DashboardQuizKey == "") {
        this.DashboardQuizKey = QuizKey;
      }
      this.setQuizKey(QuizKey);
      await this.validateQuiz(QuizKey);
    }
  },

  methods: {
    reloadPageOnDesignClick() {
      window.location.href = `/customize/create/${this.DashboardQuizKey}`;
    },
    reloadPageOnConnectClick() {
      window.location.href = `/customize/configuration/${this.DashboardQuizKey}`;
    },
    reloadPageOnShareClick() {
      window.location.href = `/customize/share/${this.DashboardQuizKey}`;
    },
    ...mapMutations(["ResetQuizId", "ResetQuizKey", "setQuizKey", "setQuizId"]),
    navigateToQuizDesign() {
      window.location.href = "/manage/quizzes";
    },

    async validateQuiz(QuizKey) {
      this.isLoading = true;

      // let RouteParam = window.location.

      const response = await this.ValidateQuizKey(QuizKey);
      if (response.status == 200 && response.data.status == "success") {
        const QuizID = response.data.data.quizId;
        this.setQuizId(QuizID);
        localStorage.setItem("QuizID", JSON.stringify(QuizID));
      } else {
        this.invalidKey = true;
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      }

      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters(["getQuizTitle", "getNavbarData"]),
    userType() {
      return this.getNavbarData.userType;
    },
    isRouteActive() {
      return this.$route.name == "Create";
    },
    isConnectRouteActive() {
      return this.$route.name == "Connect";
    },
    isShareRouteActive() {
      return this.$route.name == "Share";
    },
  },
};
</script>
