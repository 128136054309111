var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar py-2 px-3 d-lg-flex d-md-flex align-items-center justify-content-between",staticStyle:{"position":"sticky","top":"0","left":"0","z-index":"1001"}},[_c('div',{staticClass:"navbar__left mr-2 d-flex align-items-center flex-wrap"},[_c('div',{staticClass:"navbar__icon-container d-flex justify-content-center align-items-center rounded-lg flex-wrap",staticStyle:{"cursor":"pointer"},on:{"click":_vm.navigateToQuizDesign}},[_c('svg',{staticClass:"navbar__icon navbar__icon--left",attrs:{"width":"8","height":"14","viewBox":"0 0 8 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.08671 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z","fill":"white"}})])]),_c('a',{staticClass:"navbar__title ml-4",staticStyle:{"text-decoration":"none","color":"inherit","cursor":"pointer"},attrs:{"href":"/manage/quizzes"}},[_vm._v("Dashboard / "),(_vm.getQuizTitle.length < 16)?_c('span',[_vm._v(_vm._s(_vm.getQuizTitle || 'Untitled'))]):_c('span',[_vm._v(" "+_vm._s(_vm.getQuizTitle.slice(0, 16))+"...")])])]),_c('div',{staticClass:"navbar__middle d-flex align-items-center justify-content-center flex-wrap"},[_c('div',[_c('a',{staticClass:"navbar__title routeLink px-4 py-2",class:_vm.isRouteActive
            ? 'router-link-exact-active router-link-active '
            : null,staticStyle:{"text-decoration":"none","color":"inherit","cursor":"pointer"},on:{"click":_vm.reloadPageOnDesignClick}},[_vm._v(" Design ")]),_c('a',{staticClass:"navbar__title routeLink px-4 py-2",class:_vm.isConnectRouteActive
            ? 'router-link-exact-active router-link-active '
            : null,staticStyle:{"text-decoration":"none","color":"inherit","cursor":"pointer"},on:{"click":_vm.reloadPageOnConnectClick}},[_vm._v(" Configuration ")]),_c('a',{staticClass:"navbar__title routeLink px-4 py-2",class:_vm.isShareRouteActive
            ? 'router-link-exact-active router-link-active '
            : null,staticStyle:{"text-decoration":"none","color":"inherit","cursor":"pointer"},on:{"click":_vm.reloadPageOnShareClick}},[_vm._v(" Share ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }