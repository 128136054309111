<template>
   <div class="mobile-index-background">
    <!-- <loader></loader> -->
   <div class="row w-100 d-flex justify-content-center">
      <div class="col-12 p-0 mobile-content " style="text-align:center;">
        <h2 class="title mt-5" style="color: #4d1b7e;color: #4d1b7e;
font-style: normal;
font-weight: 700;
font-size: 34px;
line-height: 41px;
text-align: center;">
        The quiz is temporarily unavailable. 

        </h2>
        <h5 class="mt-5" style="color: #B3AFB6">
Please make sure that your browser has cookies enabled and try again later.
        </h5>

        <div class="quiz-footer">
          <div class=" pl-5" style="text-align:center;">
            <a href="https://www.quizell.com" target="_blank" >
                <LazyImage :src="'https://images.quizell.com/website%2FGroup%2069231%20(1).svg'" alt="" style="width:200px;height:auto" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>